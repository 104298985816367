//font-sizes
$font_1x: .75em;
$font_2x: .88em;
$font_3x: 1em;
$font_4x: 1.13em;
$font_5x: 1.25em;
$font_6x: 2.25em;
$font_7x: 2.37em;
$font_8x: 1.75em;
$font_9x: 1.62em;
$font_10x: 1.37em;

body{
  margin: 0 auto;
  padding: 0;
  background-color: #fafafa;
}
//Media quieries
$mobile_L: 480px;
$mobile: 768px;
$tablet: 1024px;
$desktop: 1280px;
@mixin for-mobile-s {
  @media (max-width: $mobile_L) { @content;}
}
@mixin for-mobile {
  @media (max-width: $mobile) { @content;}
}
@mixin for-tablet {
  @media (max-width: $tablet) { @content;}
}
@mixin for-mini-desktop {
  @media (min-width: $tablet) { @content;}
}
@mixin for-desktop {
  @media (min-width: $desktop) { @content;}
}
/*
menu-link
*/
.menu{
  padding: 0;
  margin: 0;
  position: fixed;
  width: 100%;
  display: block;
  z-index: 9999;
  @media only screen and (max-width: 1023px){
    display: none;
    top: 0;
  }
  @include for-mobile{
    margin-top: 41px;
  }
  @include for-mobile-s{
    overflow-y: scroll;
    max-height: 500px;
  }
  li{
    list-style-type: none;
    display: inline-block;
    text-align: center;
    min-width: 125px;
    @media only screen and (max-width: 1023px){
      display: block;
      width: 100%;
    }
    a{
      text-decoration: none;
      padding: 12px 0;
      font-size: 14px;
      font-family: sans-serif;
      width: 94%;
      display: block;
      cursor: pointer;
      @media only screen and (max-width: 1023px){
        text-align: left;
        width: 90%;
        position: relative;
        padding-left: 15px;
        i:before{
          position: absolute;
          right: 0;
          top: 15px;
        }
      }
    }
  }
}

.dark{
  background: #333;
  li{
    background: #333;
    &:hover{
      background: #000;
    }
    a{
      color: #aaa;
      &:hover{
        color: #fff;
      }
    }
  }
  a{
    color: #aaa;
    font-family: sans-serif;
  }
  p{
    color: #aaa;
    font-family: sans-serif;
  }
}

.hidden{
  display: none;
}
/*
accessible site
*/
.accessible-site{
  display: inline-block;
  vertical-align: middle;
  margin: 0 50px 0 0;
  position: relative;
  .icon-accessible{
    @include for-tablet{
      margin-left: 14px;
    }
    i{
      color: #aaa;
      cursor: pointer;
      font-size: 32px;
    }
  }
  .content-accessible{
    display: none;
    position: absolute;
    top: 35px;
    left: -200px;
    background: #fff;
    width: 420px;
    padding: 10px 20px;
    box-sizing: border-box;
    @media only screen and (max-width: 1023px){
      left: 0;
      padding: 10px 14px;
      top: 32px;
    }
    .content-btn-size-accessible{
      width: 55%;
      display: inline-block;
      text-align: right;
      @include for-tablet{
        width: 205px;
      }
    }
    .title-content-accessible{
      font-size: 1.25rem;
    }
    .accessible-item{
      p{
        display: inline-block;
        vertical-align: middle;
        margin: 15px;
        width: 250px;
      }
      &:last-child{
        p{
          width: 100px;
          margin: 15px 0 15px 15px;
        }
      }
    }
  }
}
/*
sistem-size
*/
.sistem-size{
  display: none;
  vertical-align: middle;
  @media only screen and (max-width: 767px){
    display: none;
  }
  .resolution-editor{
    display: inline-block;
    vertical-align: middle;
    p{
      font-size: 14px;
      margin: 0;
      input{
        background: none;
        color: #aaa;
        border: 0;
        font-family: sans-serif;
        font-size: 14px;
        width: 55px;
        text-align: right;
      }
    }
  }
  .query-size{
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    a{
      margin: 0 15px;
      text-decoration: none;
      transition: .4s;
      cursor: pointer;
      &.active{
        color: #fff;
      }
      &:hover{
        color: #fff;
      }
    }
  }
}

/*
Container
*/
.container-content{
  max-width: 100%;
  width: 100%;
  display: block;
  margin: 0 auto;
  position: relative;
  transition: max-width .5s;
  // padding-top: 40px;
  //overflow: hidden;
  z-index: 0;
  @include for-tablet{
    padding-top: 42px;
  }
  /*&:after{
    content: " ";
    position: absolute;
    display: inline-block;
    width: 3px;
    height: 100%;
    background: #333;
    top: 0;
    right: 0;
    cursor: e-resize;
    @include for-tablet{
      display: none;
    }
  }*/
  &.size-full{
    max-width: 1170px;
  }
  &.size-l{
    max-width: 1024px;
  }
  &.size-m{
    max-width: 768px;
  }
  &.size-s{
    max-width: 320px;
  }
}
/*
estilos de menu
*/
.at-menu-link{
  transition: .4s;
  position: relative;
  &:hover{
    background: #000;
    color: #fff;
    a{
      color: #fff;
    }
  }
}
.at-dropdown-menu{
  position: absolute;
  top: 100%;
  width: 400px;
  padding: 0;
  max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 9;
  @media only screen and (max-width: 1023px){
    position: relative;
    width: 100%;
  }
  li{
    display: block;
    text-align: left;
    position: relative;
    a{
      margin: 0;
      position: relative;
      padding-left: 24px;
      i{
        position: absolute;
        right: 24px;
        &:before{
          top: 0;
        }
      }
    }
  }
}
.at-dropdown-submenu{
  position: relative;
  background: #000;
  width: 100%;
  padding: 0;
  display: none;
  li{
    padding-left: 24px;
    a{
      font-size: 14px;
    }
    &.at-menu-link{
      background: none;
    }
  }
}
#hamburguer-menu{
  display: block;
  // position: fixed;
  width: 100%;
  z-index: 1;
  i{
    color: #fff;
    padding: 5px 15px;
  }
  @media only screen and (min-width: 1024px){
    display: none;
  }
}
